// vue
import { computed, type Ref } from 'vue'

// vee-validate
import type {
  FieldMeta,
  FieldValidator,
  FormMeta,
  GenericObject
} from 'vee-validate'

// utilities
import { errorsToSentence } from '@revolutionprep/utils'

export function useVeeValidate (
  meta?: Ref<FieldMeta<unknown>[] | FormMeta<GenericObject>>,
  _errors?: Ref<(string | undefined)[]> | Ref<Partial<Record<string, unknown>>>,
  fieldsValidateFunctions?: Ref<FieldValidator[]>
) {
  const areAllFieldsValid = computed(() => {
    if (!meta || !Array.isArray(meta.value)) {
      return false
    }
    return !meta.value.find((meta) => {
      return !meta.valid
    })
  })

  const isFormValid = computed(() => {
    if (!meta || Array.isArray(meta.value)) {
      return
    }
    return (meta.value.dirty && meta.value.valid)
  })

  // map model and error props
  const vuetifyConfig = (state: any) => ({
    props: {
      'error-messages': state.errors[0],
      model: 'value',
      dirty: state.dirty,
      valid: state.valid
    }
  })

  async function validateAllFields () {
    if (!fieldsValidateFunctions) {
      return
    }
    const validationResults = []
    for (const validationFunction of fieldsValidateFunctions.value) {
      const { errors } = await validationFunction()
      if (errors.length) {
        validationResults.push(errors[0])
      }
    }
    if (validationResults.length) {
      return errorsToSentence(validationResults, 'vee-validate')
    }
  }

  return {
    areAllFieldsValid,
    isFormValid,
    validateAllFields,
    vuetifyConfig
  }
}
